<template>
<div >
  <div
    style="width: 100%; height: 100%; text-align: center; padding-top: 100px"
    v-show="state.loaderEnabled"
  >
    <div class="lds-ring"><img src="media/logos/Logo-Symbole-couleur.svg"/><div></div><div></div><div></div><div></div></div>
  </div>

  <transition name="fade">

    <div class="card" v-show="state.initialMyList.length > 0 && !state.loaderEnabled">

      <el-container>
        <el-aside width="280px" style="overflow: hidden">
          <div @click="state.drawerSoc = true" class="link-primary fs-6 fw-bolder btn btn-primary w-250px mt-9 ms-9">
            {{$t("Créer un nouveau ticket")}}
          </div>

          <el-tabs class="tabsPresta pt-5" style="padding: 0 2.25rem;">
            <el-tab-pane disabled :label="$t('Synthèse et filtres')" ></el-tab-pane>
          </el-tabs>

          <div class="card-header border-0 ">
            <div class="card-title">
                <el-select v-model="state.type_stat" class="mt-3 nobg w-250px" placeholder="" size="large" >
                  <el-option :label="$t('Répartition par intervenant :')"  value="presta_nom_prenom" />
                  <el-option :label="$t('Répartition par statut :')"  value="tis_status" />
                </el-select>
            </div>
            <SyntheseIntervenant widget-classes="mb-12" :myList="state.myList" :colors="state.colors"  :mField="state.type_stat" :key="{ll:state.myList, aa:state.type_stat, bb: state.compteur}"></SyntheseIntervenant>

            <div class="card-title">
              <h4 class="fw-bolder mb-0 mt-6">{{$t("Filtrer la liste :")}}</h4>
            </div>
            <SearchAndFilter
              v-if="!state.loaderEnabled"
              :listInitial="state.initialMyList"
              :filters="tableFilters"
              @returnSearch="state.myList = $event"
              @returnLoadingDatatable="state.loadingDatatable = $event"
              widthDecalage="0"
              searchBar="0"
              classGroupSelect="align-items-center"
              classSelect="mx-0 w-250px text-primary me-3 m-2 mt-2"
              ref="filtersSAF"
            ></SearchAndFilter>
            <div class="d-flex align-items-center mt-3 mb-5">
              <p class="fs-5 text-dark fw-bolder m-0 me-3">{{$t("Rafraîchir")}}</p>
              <label class="form-check form-switch form-check-custom form-check-solid">
                <input class="form-check-input w-50px" type="checkbox" @input="handleRefreshTickets" />
              </label>
            </div>

          <!--
                    <div class="card-title">
                      <h4 class="fw-bolder mb-3 mt-4">Période:</h4>
                    </div>
                    <DatePicker v-model="state.rangeFilter" class="border-0" is-range />
        -->
          </div>


        </el-aside>
        <el-main>

          <el-tabs v-model="activeName" class="border-0 tabsPresta pt-3" style="padding: 0 2.25rem;" @tab-click="handleClickPane">

            <el-tab-pane :label="$t('Liste des tickets')" class="p-0" name="first" style="margin: 15px">

                <div class="card-header border-0 m-0 p-0">
                  <SearchAndFilter
                    v-if="!state.loaderEnabled"
                    :listInitial="state.initialMyList"
                    :filters="[]"
                    @returnSearch="state.myList = $event"
                    @returnLoadingDatatable="state.loadingDatatable = $event"
                    ExcelFilename="Stillnetwork_Tickets"
                    :ExcelFormat="ExcelFormat"
                    ref="searchFilters"
                  ></SearchAndFilter>
                </div>
                <div class="card-body pt-0 p-0">
                  <div
                    v-if="state.myList.length == 0"
                    class=" alert bg-light-primary  alert-primary  d-flex  align-items-center  p-5  mb-10"
                  >
                    <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
                    <inline-svg src="media/icons/duotune/general/gen048.svg" />
                            </span>
                            <div class="d-flex flex-column">
                    <h4 class="mb-1 text-dark">{{$t("Aucun Contrat")}}</h4>
                    <span>{{$t("Il n'existe aucun ticket à afficher correspondant à vos critères de recherche.")}}</span>
                  </div>
                  </div>
                  <Datatable
                    v-if="state.myList && state.myList.length > 0"
                    :table-data="state.myList"
                    :table-header="tableHeader"
                    :enable-items-per-page-dropdown="true"
                    empty-table-text="Aucun ticket à afficher."
                    :key="state.loadingDatatable"
                  >
                    <template v-slot:cell-tic_seq="{ row: data }">
                      <div class="fw-bolder text-gray-600 fs-6 m-titre w-200px" ><i class="fas fa-square fs-5 me-2" :style="`color: #${data.color}`"></i> {{$t("Ticket")}} #{{ data.tic_seq }}</div>
                      <div class="text-muted fw-bold d-block mt-2 m-titre" >{{ data.soc_nom_societe }}</div>
                      <div class="fw-bolder text-gray-900 fs-6 m-titre">{{ data.tic_c_label }}</div>
                    </template>

                    <template v-slot:cell-tis_status="{ row: data }">
                      <div class="d-flex flex-column" style="width:310px;">
                        <!--{{ statusJson(data.cat_status).status }}-->
                        <div style="height: 32px; line-height: 32px;" class="text-dark fw-bolder fs-7 ps-4">{{data.cat_w_name}}</div>
                        <el-select v-model="data.tis_status" class="m-2 nobg w-200px" :placeholder="$t('A définir')" size="small" @change="changeStatus(data)"
                          :disabled="data.tis_status=='Réalisé'"
                        >
                          <el-option
                            v-for="item in statusJson(data.cat_status).status"
                            :key="item.label"
                            :value="item.label"
                            :label="`${item.label == 'Réalisé' || item.label == 'Traité' ? '✅' : '⌛'} ${item.label}`"
                          >
                            <span v-if="item.next== 0">⌛ {{ item.label }}</span>
                            <span v-if="item.next==-1">🔃 {{ item.label }}</span>
                            <span v-if="item.next== 1">✅ {{ item.label }}</span>
                          </el-option>
                        </el-select>
                      </div>
                    </template>

                    <template v-slot:cell-tis_date_planif="{ row: data }">
                      <span class="text-primary fw-bolder py-1 m-2 nobg">
                        <el-date-picker v-model="data.tis_date_planif" format="DD/MM/YYYY" value-format="YYYY-MM-DD" size="small" style="width: 150px" type="date" :placeholder="$t('Planifier')"  @blur="dateChangePlanif(data)" @change="dateChangePlanif(data)"
                        :disabled="data.tis_status=='Réalisé' || data.tis_status=='Traité' " />
                      </span>
                      <el-select v-model="data.tis_knum_contact_presta" class="m-2 nobg w-150px" :placeholder="$t('A définir')" size="small" clearable  @change="changeInter(data)" >
                        <el-option
                          v-for="item in state.allContacts"
                          :key="item.value"
                          :label="item.con_nom_prenom_ab"
                          :value="item.con_seq"
                        />
                      </el-select>
                    </template>

                    <template v-slot:cell-custom_datas="{ row: data }">
                      <div style="width: 200px" v-if="data.cat_json_custom && isJSON(data.cat_json_custom) && JSON.parse(data.cat_json_custom).length > 0">
                        <table>
                              <template v-for="(item2, index) in JSON.parse(data.cat_json_custom)" :key="index"> 
                                <tr>
                                    <td class="fs-8 text-gray-600 pt-1 pb-1 w-100px">{{ $t(item2.name) }} </td>
                                    <td class="fs-7 text-gray-800 pt-1 pb-1" v-html="getCustomValue(item2, data)"></td>
                                </tr>
                              </template>
                          </table>
                      </div>
                    </template>

                    



                    <template v-slot:cell-total_files="{ row: data }">
                      <span v-if=" data.total_files>0">
                      {{ data.total_files }} {{$t("fichier")}}{{ data.total_files>0 ? $t('s') : '' }}
                      </span>
                      <span v-else class="text-danger">
                        {{$t("Aucun fichier")}}
                      </span>                  
                    </template>

                    <template v-slot:cell-tic_knum_societe="{ row: data }">
                    <a class="btn btn-sm btn-active-primary me-2 mb-2 btn-bg-white btn-text-dark" @click='affDetail(data.tic_seq, data.tis_seq)'>
                        <span class="svg-icon svg-icon-1 svg-icon-primary">
                          <inline-svg src="media/icons/duotune/general/gen004.svg"/> </span>
                          {{$t("Afficher")}}
                      </a>
                    </template>
                    
                  </Datatable>
                </div>

            </el-tab-pane>
            
            <el-tab-pane :label="$t('Planning d\'interventions')" name="second">
              <PlanningPresta widget-classes="mb-12" @affTicket="affTicket($event)" :myList="state.myList" :mField="state.type_stat" :key="{ll:state.myList, aa:state.type_stat, cc: state.compteur}"></PlanningPresta>
            </el-tab-pane>

            <el-tab-pane :label="$t('cartographie')" name="third">
              <div style="height: calc(100vh - 240px);">
                <MapPresta v-if="state.affMap" widget-classes="mb-12" @affTicket="affTicket($event)" :myList="state.myList" :mField="state.type_stat" :key="{ll:state.myList, aa:state.type_stat, cc: state.compteur}"></MapPresta>
              </div>
            </el-tab-pane>

          </el-tabs>

        </el-main>
      </el-container>

    </div>
  </transition>
  <el-drawer
    v-model="state.drawerSoc"
    title=""
    :with-header="false"
    size="40%"
  >
  <div class="d-flex justify-content-between align-items-center p-4 card-header">
    <button @click="state.drawerSoc = false" class="btn btn-light me-3"><i class="fas fa-arrow-left fs-4 me-2" ></i> {{$t("Retour")}}</button>
    <h4 class="text-center">{{$t("Créer un nouveau ticket")}}</h4>
    <button  @click="nextPrestaTicket()" class="btn btn-primary">
      {{$t("Continuer")}}
      <span class="svg-icon svg-icon-3 ms-1 me-0">
        <inline-svg src="media/icons/duotune/arrows/arr064.svg" />
      </span>
    </button>
  </div>

  <div class="d-flex flex-column mt-2 p-10">
    <div>
      <h2 class="fw-bolder text-dark">{{$t("Sociétés")}}</h2>
      <div class="text-gray-400 fw-bold fs-6">
        {{$t("Veuillez sélectionner une société.")}}
      </div>
      <el-select v-model="state.selectedSoc" @change="setContactList()" class="mt-3 nobg w-250px" placeholder="" size="large" >
        <el-option v-for="item, index in state.listSocs" :key="index" :label="item.soc_nom_societe" :value="item.soc_seq" />
      </el-select>
    </div>

  <div class="mt-15">
    <div class="pb-1">
      <div class="d-flex justify-content-between align-items-end mt-4 mb-8">
        <div>
          <h2 class="fw-bolder text-dark">{{$t("Contacts")}}</h2>
          <div class="text-gray-400 fw-bold fs-6">
            {{$t("Veuillez sélectionner un contact.")}}
          </div>
        </div>
        <a class="btn btn-sm btn-active-secondary btn-bg-primary mt-1 btn-text-white" @click="new_contact();" v-if="!state.newContact && state.selectedSoc>0">
          {{$t("Créer un nouveau contact")}}
        </a>
      </div>
        <SearchAndFilter
          v-if="!state.loaderEnabled"
          :listInitial="state.initialPrestaContacts"
          :filters="[]"
          @returnSearch="state.prestaContacts = $event"
          @returnLoadingDatatable="state.loadingDatatable = $event"
          :widthDecalage="-155"
        ></SearchAndFilter>
    </div>
    <div class="d-flex flex-column mb-7 fv-row">
        <div class="table-responsive" style="max-height: calc(100vh - 400px);">
          <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            <thead>
              <tr class="fw-bolder text-muted">
                <th class="w-25px" v-if="!state.newContact"></th>
                <th class="min-w-150px">{{$t("Contact")}}</th>
                <th class="min-w-150px">{{$t("Coordonnées")}}</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="state.newContact">
                <tr style='background-color: white !important'>
                  <!-- <td>
                    <div class="form-check form-check-sm form-check-custom form-check-solid">
                      <input class="form-check-input widget-9-check" type="checkbox" />
                    </div>
                  </td> -->
                  <td>
                    <div class="d-flex align-items-center">
                      <div class="d-flex justify-content-start flex-column">
                          <Field name="new_contact_civilite" class="form-select form-select-lg form-select-solid mt-2" style="width: 100%; display: block; border: 1px solid #dcdfe6; height: 43px" as="select" v-model="state.con_civilite">
                            <option value='0'>{{$t("Monsieur")}}</option>
                            <option value='1'>{{$t("Madame")}}</option>
                          </Field>
                          <Field  name="new_contact_prenom" :placeholder="$t('Prénom')" v-model="state.con_prenom" class="form-control form-control form-control-solid mt-2" style="width: 100%; display: block;" />
                          <Field  name="new_contact_nom" :placeholder="$t('Nom')" v-model="state.con_nom" class="form-control form-control form-control-solid mt-2" style="width: 100%; display: block;" />
                      </div>
                    </div>
                  </td>
                  <td>
                    <Field  name="new_contact_tel1" v-model="state.con_tel1" :placeholder="$t('Télephone Fixe')" class="form-control form-control form-control-solid mt-2" />
                    <Field  name="new_contact_tel2" v-model="state.con_tel2" :placeholder="$t('Télephone Mobile')" class="form-control form-control form-control-solid mt-2" />
                    <Field  name="new_contact_mail" v-model="state.con_mail" :placeholder="$t('Adresse mail')" class="form-control form-control form-control-solid mt-2" />
                  </td>
                </tr>
                <tr style='background-color: white !important; text-align: right;'>
                  <td colspan=3>
                    <a class="btn btn-sm btn-active-secondary me-2 mb-2 btn-bg-white btn-text-dark" @click="state.newContact = false">
                      {{$t("Annuler")}}
                    </a>
                    <a class="btn btn-sm btn-active-secondary me-2 mb-2 btn-bg-primary btn-text-white" @click="saveNewContact()">
                      {{$t("Créer le contact")}}
                    </a>
                  </td>
                </tr>
                </template>
                <template v-if="!state.newContact">
                  <template v-for="(item, index) in state.prestaContacts" :key="index" >
                    <tr @click="state.selectedContact = item.con_seq">
                      <td>
                        <div class="form-check form-check-sm form-check-custom form-check-solid">
                          <input class="form-check-input widget-9-check" type="radio" :value="item.con_seq" v-model="state.selectedContact" :key="item.con_seq"/>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex align-items-center">
                          <div class="d-flex justify-content-start flex-column">
                            <a class="text-dark fw-bolder text-hover-primary fs-6">{{ item.con_nomprenom }}</a>
                            <span class="text-muted fw-bold text-muted d-block fs-7">{{ item.con_mail }}</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <a :href="'sip://'+item.con_tel1" class="text-dark fw-bolder text-hover-primary d-block fs-7">{{ item.con_tel1 }}</a>
                        <a :href="'sip://'+item.con_tel2" class="text-dark fw-bolder text-hover-primary d-block fs-7">{{ item.con_tel2 }}</a>
                      </td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
  </div>
  </el-drawer>

  <el-drawer v-model="state.drawer" :with-header="false" size="90%" modal-class="modal-transp" v-show="state.ticketSelect.tic_seq">
    <div class="card shadow-none" style="width: 100%; height: 100%;" v-if="state.drawer">
      <div class="card-header" id="kt_activities_header" style="background: white;">
        <div class="page-title d-flex  align-items-center  flex-wrap me-3 mb-5 mb-lg-0 ">
          <button @click="state.drawer = false" class="btn btn-light me-3"><i class="fas fa-arrow-left fs-4 me-2" ></i> {{$t("Retour")}}</button>

          <template v-if="state.onUpload==0">
            <el-upload ref="uploadRef" action="#" :on-change="submitUpload" :multiple=true :show-file-list="false" :auto-upload="false">
              <button class="btn btn-primary me-3"><i class="fas fa-upload fs-4 me-2" ></i>{{$t("Joindre un nouveau fichier")}}</button>
            </el-upload>
          </template>
          <template v-else>
            <div class="btn btn-info me-3"><i class="fas fa-circle-notch fa-spin fs-4 me-2" style="text-indent: 5px;"></i>{{$t("Chargement du fichier...")}}</div>
          </template>

          <span class="text-primary fw-bolder py-1 m-2 nobg">
            <el-date-picker v-model="state.valTicket.tic_c_serv[0].tis_date_planif" format="DD/MM/YYYY" value-format="YYYY-MM-DD" style="width: 150px" type="date" :placeholder="$t('Planifier')"  @blur="dateChangePlanif(state.valTicket.tic_c_serv[0])" @change="dateChangePlanif(state.valTicket.tic_c_serv[0])"
            :disabled="!(state.valTicket.tic_c_serv[0].tis_status=='En cours de planification' || !state.valTicket.tic_c_serv[0].tis_date_planif)" />
          </span>
          <el-select v-model="state.valTicket.tic_c_serv[0].tis_knum_contact_presta" class="m-2 nobg w-250px" :placeholder="$t('Intervenant à définir')" size="large" clearable  @change="changeInter(state.valTicket.tic_c_serv[0])" >
            <el-option
              v-for="item in state.allContacts"
              :key="item.value"
              :label="item.con_nom_prenom_ab"
              :value="item.con_seq"
            />
          </el-select>

          <el-select v-model="state.valTicket.tic_c_serv[0].tis_status" class="m-2 nobg w-250px" :placeholder="$t('A définir')" size="large" @change="changeStatus(state.valTicket.tic_c_serv[0])"
            :disabled="state.valTicket.tic_c_serv[0].tis_status=='Réalisé'"
          >
            <el-option
              v-for="item in statusJson(state.valTicket.tic_c_serv[0].cat_status).status"
              :key="item.label"
              :value="item.label"
              :label="`${item.label == 'Réalisé' || item.label == 'Traité' ? '✅' : '⌛'} ${item.label}`"
            >
              <span v-if="item.next== 0">⌛ {{ item.label }}</span>
              <span v-if="item.next==-1">🔃 {{ item.label }}</span>
              <span v-if="item.next== 1">✅ {{ item.label }}</span>
            </el-option>
          </el-select>
          
        </div>
      </div>
      <div class="card-body position-relative" id="kt_activities_body" style="overflow: auto !important;  height: calc(100vh - 100px); background: url(media/still/backg.jpg) no-repeat center center fixed;  background-size: cover;">
        <el-main class='pt-0' style="padding-right: 0 !important;">
          <el-container>
            <el-main class='pt-0' style='padding-left: 13px !important;'>
              <affTicket v-if="!state.loaderEnabled" :objTicket="state.valTicket" :key="state.valTicket" :modeTic='1' @reloadTicket="affDetail(state.selTicket, state.valTis);" />
            </el-main>
            <el-aside width="350px" class="d-none d-xxl-block">
              <chat :deltaHauteur="state.hauteurChat" class="m-ticket" :style="`border-radius: 5px; cursor: default; position: fixed;right: 15px;width: 358px;top:${state.topChat}px`"  dis_type_entity="ticket"  :dis_knum_entity="state.selTicket" :key="state.selTicket || state.hauteurChat || state.renderChat" v-if="state.selTicket > 0" />
            </el-aside>
          </el-container>

        </el-main>
          
      </div>
    </div>
  </el-drawer>
  </div>
  <div class="card" v-if="state.initialMyList.length == 0 && !state.loaderEnabled">
    <el-empty :description="$t('Vous n\'avez aucun ticket à traiter pour le moment')">
      <div @click="state.drawerSoc = true" class="link-primary fs-6 fw-bolder btn btn-primary w-250px mt-2">
        {{$t("Créer un nouveau ticket")}}
      </div>
    </el-empty>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, reactive, onUnmounted } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as moment from "moment";
import { useRouter } from "vue-router";
import SearchAndFilter from "@/views/still/search_and_filters.vue";
import affTicket from "@/views/still/deploy/affTicket.vue";
import chat from "@/views/still/chat/chat.vue";
import { ElNotification } from 'element-plus'
import { ElMessageBox } from 'element-plus'
import { useStore } from "vuex";
import SyntheseIntervenant from "@/views/still/fournisseur/syntheseIntervenant.vue";
import PlanningPresta from "@/views/still/fournisseur/planningPresta.vue";
import MapPresta from "@/views/still/fournisseur/mapPresta.vue";
import { useI18n } from "vue-i18n";

// import { DatePicker } from 'v-calendar';

import mAxiosApi from "@/api";
import { Field } from "vee-validate";

export default defineComponent({
  name: "Liste des commandes en cours",
  components: {
    Datatable,
    SearchAndFilter,
    affTicket,
    chat,
    SyntheseIntervenant,
    // DatePicker,
    PlanningPresta,
    MapPresta,
    Field
  },

  emits: ["refreshRouter"],

  setup(props, { emit }) {
    //moment.default.locale("fr");
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      loaderEnabled: true,
      allContacts: [] as any,
      myList: [] as any,
      drawer: ref(false),
      drawerImport: ref(false),
      sourcePDF: "",
      mFact: "",
      initialMyList: [] as any,
      loadingDatatable: 0,
      search: "",
      ticketSelect: {} as any,
      valTis: 0,
      valTicket: {} as any,
      selTicket : 0,
      topChat : 95,
      hauteurChat: 124,
      loaderUpload : false,
      role_web : store.getters.currentUser.con_role_web,
      type_stat : 'tis_status',
      compteur : 0,
      onUpload : 0,
      affMap : false,
      rangeFilter: {
        start: new Date(2020, 0, 1),
        end: new Date(2020, 0, 5)
      },
      colors:['33b1f9','fd9800','8fbf30','cd0067','6601ff','006766','0000cc','019934','fe0000','33b1f9','fd9800','8fbf30','cd0067','6601ff','006766','0000cc','019934','fe0000'],
      drawerSoc: false,
      con_civilite: 0,
      con_prenom: "",
      con_nom: "",
      con_tel1: "",
      con_tel2: "",
      con_mail: "",
      newContact: false,
      listContacts: [] as any,
      formData: {} as any,
      listSocs: [] as any,
      selectedSoc: '',
      selectedContact: 0,
      initialPrestaContacts: [] as any,
      prestaContacts: [] as any,
      refreshTickets: false,
      refreshInterval: 0,
      renderChat: 0,
      sendingMessage: false,
    });
    const { t } = useI18n()
    const searchFilters = ref(null) as any
    const filtersSAF = ref(null) as any

    const tableHeader = ref([
      {
        name: 'Ticket',
        key: "tic_seq",
        sortable: true,
      },
      {
        name: 'Planification',
        key: "tis_date_planif",
        sortable: true,
      },
      {
        name: 'Statut',
        key: "tis_status",
        sortable: true,
      },
      {
        name: ' ',
        key: "custom_datas",
        sortable: true,
      },
      {
        name: 'Actions',
        key: "tic_knum_societe",
        sortable: true,
      },
    ]);

    const tableFilters = ref([
      {
        name: 'Statut',
        key: "tis_status",
        label: "tis_status",
      },
      {
        name: 'Service',
        key: "cat_w_name",
        label: "cat_w_name",
      },
      {
        name: 'Client',
        key: "soc_nom_societe",
        label: "soc_nom_societe",
      },
      {
        name: 'Intervenant',
        key: "presta_nom_prenom",
        label: "presta_nom_prenom",
      },
      {
        name: 'Planification',
        key: "presta_date_planif",
        label: "presta_date_planif",
      },
      {
        name: 'Pièces jointes',
        key: "xfile",
        label: "xfile",
      },
    ]);

    const ExcelFormat = ref({
      "Ticket": "tic_seq",
      "Service": "cat_w_name",
      "Création": "tic_d_creation",  
      "Libellé du ticket": "tic_c_label",
      "Intervenant": "presta_nom_prenom",
      "Statut": "tis_status",
    });


    const handleClickPane = () => {
      
      if (activeName.value=='third') {
        setTimeout(() => {
          state.affMap = activeName.value=='third'
        }, 500)
      } else {
        state.affMap = false;
      }


      
    }

    const activeName = ref('first')

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };

    const test_3_month = (d) => {
      const currentDate = moment.default().add(3, 'M');
      return moment.default(d) > currentDate;
    }

    const refreshRouter = () => {
      emit("refreshRouter" , 1);
    }

    const reloadRow = async (tisSeq) => {
      const itemColor = state.myList.filter(el => el.tis_seq == tisSeq)[0].color;
      const getTicketsPresta = await mAxiosApi.prototype.getAxios("/getTicketsPresta/"+ tisSeq);
      Object.keys(state.myList.filter(element=> element.tis_seq == tisSeq)[0]).forEach(function(key) {
        state.myList.filter(element=> element.tis_seq ==tisSeq)[0][key] = getTicketsPresta.results[0][key];
      })
      state.myList.filter(el => el.tis_seq == tisSeq)[0].color = itemColor;
      state.myList.filter(element=> element.tis_seq == tisSeq)[0].old_tis_date_planif = state.myList.filter(element=> element.tis_seq == tisSeq)[0].tis_date_planif;
      state.myList.filter(element=> element.tis_seq == tisSeq)[0].old_tis_knum_contact_presta = state.myList.filter(element=> element.tis_seq == tisSeq)[0].tis_knum_contact_presta;
    }


    const applyChange = async (data, type) => {
      if (!state.sendingMessage) {
        state.sendingMessage = true;

        let stringDate = ''
        if(data.tis_date_planif){
          data.tis_date_planif = moment.default(data.tis_date_planif).format("YYYY-MM-DD");
          stringDate = moment.default(data.tis_date_planif).format("D MMMM YYYY");
        }
        state.compteur = state.compteur +1;
        data.type = type;
        if (!data.tic_seq) data.tic_seq = state.selTicket;

        mAxiosApi
        .post("updateInterDate", data)
        .then(async () => {
          state.sendingMessage = true;
          await reloadRow(data.tis_seq);
        })
        .catch((err) => {
          console.error(err);
        });
      }
      setTimeout(() => {
        state.sendingMessage = false;
      }, 500);
    };



    const affDetail = async (ticSeq, tisSeq = 0, xDisplay = true) => {
      await reloadRow(tisSeq);
      state.selTicket = ticSeq;
      state.valTis = tisSeq;
      let myList = await mAxiosApi.prototype.getAxios("/getTicket/" + ticSeq + "/" + tisSeq);
      myList.results.tic_staging.forEach((el) => {
        el.fichiers = JSON.parse(el.fichiers);
      })
      state.valTicket = myList.results;    
      if (xDisplay) state.drawer = true;
    };


    const changeStatus = async (data) => {

      if (data.tis_status == "Réalisé") {

        if (!data.tis_date_planif) { 
          ElMessageBox.alert(t("Impossible ! Aucune date d'intervention !"), t("Erreur"), {
            confirmButtonText: t("OK"),
            callback: () => {
              if (state.valTicket) { affDetail(state.valTicket.tic_seq, data.tis_seq, false); }else { reloadRow(data.tis_seq); }
            },
          })
          return false;
         }

        if (!data.tis_knum_contact_presta) { 
          ElMessageBox.alert(t("Impossible ! Aucun intervenant défini !"), t("Erreur"), {
            confirmButtonText: t("OK"),
            callback: () => {
              if (state.valTicket) { affDetail(state.valTicket.tic_seq, data.tis_seq, false); }else { reloadRow(data.tis_seq); }
            },
          })
          return false;
         }

         

        ElMessageBox.confirm(
          t('Une fois que le statut sera passé à "Réalisé", il vous sera impossible de modifier l\'intervenant et la date du ticket. Souhaitez-vous passer ce ticket en "Réalisé" ?'), t("Confirmation"),
          { confirmButtonText: t("Oui"), cancelButtonText: t("Non, annuler"), type: 'warning', }
        )
        .then(() => {
          applyStatus(data);
        })
        .catch(async () => {
          if (state.valTicket) { affDetail(state.valTicket.tic_seq, data.tis_seq, false); }else { await reloadRow(data.tis_seq); }
        })
      }else {
        applyStatus(data);
      }
    };

    const applyStatus = async (data) => {

        state.compteur = state.compteur +1;

        state.myList.filter(element=> element.tis_seq == data.tis_seq)[0].tis_status = data.tis_status;
        state.initialMyList.filter(element=> element.tis_seq == data.tis_seq)[0].tis_status = data.tis_status;
        if (!data.tic_seq) data.tic_seq = state.selTicket;

        mAxiosApi
          .post("updateStatusNext", data)
          .then(async () => {
            await reloadRow(data.tis_seq);
          })
          .catch((err) => {
            console.error(err);
          });


    };


    const affTicket = (data) => {
      affDetail(data.tic_seq, data.tis_seq);
    }

    const styleImgStatus = (data) => {
      if (data.tic_c_status == "Traité") {
        return "media/icons/duotune/arrows/arr085.svg";
      } else {
        return "media/icons/duotune/general/gen012.svg";
        
      }
    };

    const styleBtnStatus = (data) => {
      if (data.tic_c_status == "Traité") {
        return "btn-icon-success bg-white";
      } else {
         return "btn-icon-dark bg-white svg-icon svg-icon-sm-6 ";
      }
    };

    const dateChangePlanif= (data) => {

      if (!data.tis_date_planif) data.tis_date_planif = null;
      
      state.myList.filter(element=> element.tis_seq == data.tis_seq)[0].tis_date_planif = data.tis_date_planif;
      state.initialMyList.filter(element=> element.tis_seq == data.tis_seq)[0].tis_date_planif = data.tis_date_planif;

      applyChange(data, 'planif');
    };

    const changeInter= (data) => {
      // console.log(state.myList.filter(element=> element.tis_seq == data.tis_seq));

      if (!data.tis_knum_contact_presta) data.tis_knum_contact_presta = null;

      state.myList.filter(element=> element.tis_seq == data.tis_seq)[0].tis_knum_contact_presta = data.tis_knum_contact_presta;
      state.initialMyList.filter(element=> element.tis_seq == data.tis_seq)[0].tis_knum_contact_presta = data.tis_knum_contact_presta;

      applyChange(data, 'inter');
    };

    const compressImage = async (file, { quality = 1, type = file.type }) => {
        // Get as image data
        const imageBitmap = await createImageBitmap(file);

        // Draw to canvas
        const canvas = document.createElement('canvas');
        
        const typeImg = imageBitmap.width > imageBitmap.height ? "W" : "H";
        const typeSize = typeImg == "W" ? imageBitmap.width : imageBitmap.height;
        
        let ratio = 1;
        
       
       if (typeSize> 1800) {
        if (typeImg == "W" ) {
            ratio = imageBitmap.width / imageBitmap.height;
            canvas.width = 1800;
            canvas.height = 1800 / ratio;
        } else {
            ratio = imageBitmap.height / imageBitmap.width;
            canvas.height = 1800;
            canvas.width = 1800 / ratio;
        }
       }else {
          canvas.width = imageBitmap.width;
          canvas.height = imageBitmap.height;
       }


        const ctx = canvas.getContext('2d');
        if (ctx) {
          //ctx.drawImage(imageBitmap, 0,0);
          ctx.drawImage(imageBitmap, 0,0, imageBitmap.width, imageBitmap.height,0 ,0 , canvas.width, canvas.height); 
        } 

        // Turn into Blob
        return await new Promise((resolve) =>
            canvas.toBlob(resolve, type, quality)
        );
    };


    const submitUpload = async () => {
      state.onUpload = 1;
      state.loaderUpload = true;
      const file1: any = document.querySelector('input[type=file]');

      const file = file1.files[0];

        if (file) {
            var reader = new FileReader();

            if (file.type.startsWith('image')) {
              const compressedFile: any = await compressImage(file, {
                  quality: 0.5,
                  type: 'image/jpeg',
              });
              reader.readAsDataURL(compressedFile);
            }else {
              reader.readAsDataURL(file);
            }
            reader.onload = async function(e) {
                if(e.target){
                    const form = {
                      tif_knum_ticket: state.selTicket,
                      tif_knum_ticket_services: state.valTis,
                      files : [{ name: file.name, data: e.target.result, size: file.size }], //e.target.result
                    }
                    //console.log(form);
                    mAxiosApi
                      .post("/addFileTicketService", form)
                      .then( async() => {
                        affDetail(state.selTicket, state.valTis);
                        state.onUpload = 0;
                        ElNotification({
                          title: t("Succès"),
                          message: t("Fichier ajouté"),
                          type: 'success',
                        })
                      })

                } 
            };
      }

    }

    const statusJson = (str) =>{
      return JSON.parse(str);
    } ;

    function isJSON(str) {
        try {
            return (JSON.parse(str) && !!str);
        } catch (e) {
            return false;
        }
    }

    onMounted(async () => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Suivi des prestations", []);

      let myContacts = await mAxiosApi.prototype.getAxios("/getContacts");
      state.allContacts = myContacts.results;

      let listSocs = await mAxiosApi.prototype.getAxios("/getPrestaSocs");
      state.listSocs = listSocs.results
      state.selectedSoc = state.listSocs[0].soc_seq

      await getAllTickets();
      state.myList = state.initialMyList;      

      if (state.myList.length > 0){
        if (router.currentRoute.value.params.ticid) {
          affDetail(router.currentRoute.value.params.ticid);
        }
        if (router.currentRoute.value.params.status) {
          tableFilters.value.filter(el => el.key=="tic_c_status")[0]['default'] = [router.currentRoute.value.params.status];
        }
      }

      state.loaderEnabled = false;
      setContactList()
    });

    const getAllTickets = async() => {
      let myList = await mAxiosApi.prototype.getAxios("/getTicketsPresta");

      myList.results.forEach(
        function(item: any){
          if (item.tis_date_planif){
            item.tis_date_planif = moment.default(item.tis_date_planif + ' 12:00 ');
          } 

          item.old_tis_date_planif = item.tis_date_planif;
          item.old_tis_knum_contact_presta = item.tis_knum_contact_presta;

        }
      );

      state.initialMyList = myList.results;
    }

    const setContactList = async() => {
      let prestaContacts = await mAxiosApi.prototype.getAxios("/getPrestaContacts/" + state.selectedSoc);
      state.initialPrestaContacts = prestaContacts.results 
      state.prestaContacts = prestaContacts.results;
      state.selectedContact = state.prestaContacts[0].con_seq;
    }

    function new_contact() {
      state.con_civilite = 0;
      state.con_prenom = "";
      state.con_nom = "";
      state.con_tel1 = "";
      state.con_tel2 = "";
      state.con_mail = "";
      state.newContact = true;
    }

    const saveNewContact = async () => {
        mAxiosApi
          .post("addContact", { con_civilite: state.con_civilite, con_prenom: state.con_prenom, con_nom: state.con_nom, con_tel1: state.con_tel1, con_tel2: state.con_tel2, con_mail: state.con_mail, con_knum_adr: null, con_knum_societe: state.selectedSoc })
          .then( async () => {
            await setContactList();
            state.newContact = false;
          })
          .catch((err) => {
            console.error(err);
          });
    };

    const nextPrestaTicket = async() => {
      if (state.selectedContact>0) {
        await mAxiosApi.get("/setPrestaSoc/" + state.selectedContact)
        router.push( {name: "addupdateticket", params: {prc_knum_client: state.selectedSoc}})
      }else{
        ElNotification({
            title: t("Attention !"),
            message: t("Veuillez choisir un contact"),
            type: 'error',
          })
      }
    }

    const getCustomValue = (data, data2) => {
      let ssArray = JSON.parse(data2.tis_custom);
      if (ssArray && ssArray[data.name]) {
        ssArray = ssArray[data.name];
      } else {
        return "<i class='fs-8 text-gray-500'>non renseigné</i>";
      } 

      let data3 = data.values.filter(el => el.value == ssArray)[0];
      if(data3 && data3.icon ) return data3.icon + " " + t(data3.value)
      return "<i class='fs-8 text-gray-500'>non renseigné</i>";

    }

    const handleRefreshTickets = () => {
      state.refreshTickets = !state.refreshTickets
      if (state.refreshTickets) {
        state.refreshInterval = setInterval(async() => {
          await getAllTickets();
          filtersSAF.value?.newInitialList(state.initialMyList);
          if (searchFilters.value?.isThereSearch()) {
            searchFilters.value?.newInitialList(state.initialMyList);
          }
        }, 180000)
        
      }else{
        clearInterval(state.refreshInterval)
      }
    }

    onUnmounted(() => {
      clearInterval(state.refreshInterval);
    })

    return {
      tableHeader,
      state,
      formatDate,
      test_3_month,
      affDetail,
      tableFilters,
      ExcelFormat,
      refreshRouter,
      styleImgStatus,
      styleBtnStatus,
      dateChangePlanif,
      changeInter,
      applyChange,
      submitUpload,
      changeStatus,
      handleClickPane,
      activeName,
      affTicket,
      statusJson,
      setContactList,
      new_contact,
      saveNewContact,
      nextPrestaTicket,
      handleRefreshTickets,
      searchFilters,
      filtersSAF,
      isJSON,
      getCustomValue
    };
  },
});
</script>

<style>
.el-dropdown-menu__item:hover {
  cursor: pointer;
  background-color: #e4e7ed !important;
}
</style>